

































import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import DateGroup from '@/components/organisms/h/item/date/DateGroup.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import ExtractConditionRowLayout from '@/components/organisms/h/layout/ExtractConditionRowLayout.vue'
import {
  OrderPatternSettingSiiExtractCondition,
  dateGroupExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
  dateRange,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    DateGroup,
    ItemHierarchyGroup,
    HLine,
  },
  props: {
    defaultDateValue: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
  },
  setup(props, context) {
    /*********************************
     * 表示する選択肢
     *********************************/
    // Todo: TanaUseListにクラス、カテゴリ追加
    const storeUseList = ref<itemHierarchyUseList>([['StoreShelve', 'Store']])
    const itemUseList = ref<itemHierarchyUseList>([['Shobunrui']])
    const vendorUseList = ref<itemHierarchyUseList>([['Vendor']])
    /*********************************
     * データ
     *********************************/
    const extractConditionOfDate = ref<dateGroupExtractCondition>({})
    const extractConditionOfStore = ref<itemHierarchyGroupExtractCondition>({})
    const extractConditionOfItem = ref<itemHierarchyGroupExtractCondition>({})
    const extractConditionOfVendor = ref<itemHierarchyGroupExtractCondition>({})

    /*********************************
     * emitデータの作成
     *********************************/
    // いずれかの抽出条件変更時の処理
    const whereValidData = ref<string[]>(props.defaultDateValue)
    const changeExtractCondition = () => {
      const base: OrderPatternSettingSiiExtractCondition = {
        validDate:
          extractConditionOfDate.value.dateRange ||
          (props.defaultDateValue as dateRange),
        areas: extractConditionOfStore.value.storeShelve || [],
        areasCount: extractConditionOfStore.value.storeShelveCount || 0,
        stores: extractConditionOfStore.value.store || [],
        storesCount: extractConditionOfStore.value.storeCount || 0,
        shobunruis: extractConditionOfItem.value.shobunrui || [],
        shobunruisCount: extractConditionOfItem.value.shobunruiCount || 0,
        vendors: extractConditionOfVendor.value.vendor || [],
        vendorsCount: extractConditionOfVendor.value.vendorCount || 0,
      }
      let cond = { ...base }
      // console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
      whereValidData.value =
        extractConditionOfDate.value.dateRange || props.defaultDateValue
    }

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
    const changeExtractConditionOfDate = (
      params: dateGroupExtractCondition
    ) => {
      extractConditionOfDate.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfStore.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfItem = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfItem.value = params
      changeExtractCondition()
    }

    const changeExtractConditionOfVendor = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfVendor.value = params
      changeExtractCondition()
    }
    const requiredItemInput = [
      '有効日：常に入力',
      '店舗エリア・店舗・小分類・発注先のいずれかを一つ以上選択',
    ]
    return {
      requiredItemInput,
      itemUseList,
      storeUseList,
      vendorUseList,
      whereValidData,
      changeExtractConditionOfDate,
      changeExtractConditionOfItem,
      changeExtractConditionOfStore,
      changeExtractConditionOfVendor,
    }
  },
})
